<template>
  <section>
    <v-breadcrumbs
      class="text--secondary"
      large
      :items="breadcrumbsItems"
    ></v-breadcrumbs>
    <div class="my-8">
      <v-col cols="6">
        <ObsPreviewComponent :obs="productoSeleccionado" :title="false" />
      </v-col>
    </div>
    <div class="my-8 py-8">
      <p>Insumos solicitados</p>
      <v-data-table
        :headers="headers"
        :items="items"
        class="mb-5"
        hide-default-footer
        :items-per-page="-1"
      >
        <template #[`item.precio_unitario`]="{ item }">
          {{
            Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(item.precio_unitario)
          }}
        </template>
        <template #[`item.total`]="{ item }">
          {{
            Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(item.total)
          }}
        </template>
      </v-data-table>
    </div>
    <div class="my-8">
      <v-btn color="secondary" @click="regresar()" text>Volver</v-btn>
    </div>
  </section>
</template>
<script>
import { mapState, mapMutations, mapActions } from "vuex";
import ObsPreviewComponent from "@/components/ObsPreviewComponent.vue";
export default {
  name: "verInsumoDetalleView",
  components: {
    ObsPreviewComponent,
  },
  data: () => ({
    breadcrumbsItems: [],
    items: [],
    headers: [
      {
        text: "Correlativo",
        align: "center",
        value: "correlativo",
        width: "10%",
        sortable: false,
      },
      {
        text: "Mes",
        align: "center",
        value: "mes",
        width: "20%",
        sortable: false,
      },
      {
        text: "Cantidad",
        align: "center",
        value: "cantidad",
        width: "10%",
        sortable: false,
      },
      {
        text: "Precio unitario ($)",
        align: "center",
        value: "precio_unitario",
        width: "10%",
        sortable: false,
      },
      {
        text: "Total ($)",
        align: "center",
        value: "total",
        width: "20%",
        sortable: false,
      },
    ],
  }),
  computed: {
    ...mapState("agregarInsumo", [
      "proveedorInfo",
      "id_solicitud",
      "nuevoInsumo",
    ]),
    ...mapState("comprasModule", ["correlativoSolicitud"]),
    ...mapState("catalogo", ["productoSeleccionado"]),
  },
  methods: {
    regresar() {
      this.$router.push({
        name: "proveedor-detalle",
        params: { tipo: Number(this.$route.params.tipo) },
      });
    },
  },
  async created() {
    this.breadcrumbsItems = [
      {
        text: this.correlativoSolicitud,
      },
      {
        text: "Proveedores",
      },
      {
        text: this.proveedorInfo?.ganador_obs,
      },
      {
        text: `${this.productoSeleccionado?.codigo} - ${this.productoSeleccionado?.obs}`,
      },
    ];
    this.items = this.nuevoInsumo.solicitud_compra_detalle_obs.map(
      (col, index) => ({
        correlativo: index + 1,
        precio_unitario: this.productoSeleccionado.precio_unitario,
        total: col.cantidad * this.productoSeleccionado.precio_unitario,
        mes: col.mes,
        id_mes: col.id_mes,
        cantidad: col.cantidad,
        id: col.id,
      })
    );
  },
};
</script>
<style scoped lang="scss">
.theme--light.v-application .text--secondary {
  color: #404a7a !important;
}

.v-application ul {
  padding-left: 2px !important;
}
</style>
